import React from "react";
import { Table } from "antd";
import { COULUMN_HEADINGS } from "../constants";

export const AboutUs = ({ aboutData }) => {
  return (
    <>
      <footer id="aboutus" className="footer section has-bg-image text-center" style={{ backgroundImage: "url('/static/frontend/images/footer-bg.jpg')" }}>
        <div className="container max-w-full">
          <div className="footer-top grid-list p-[20px]">
            <section className="footer-brand has-before has-after" id="aboutus">
              {/* <div className="container"> */}
              <div className="form  bg-black-12">
                <div className="form-right text-center" style={{ backgroundImage: `url('/static/frontend/images/form-pattern.png')` }} id="timings">
                  <h2 className="headline-1 text-center">About Us</h2>

                  <p className="contact-label">Booking Request</p>

                  <a href="tel:+353899705390" className="body-1 contact-number hover-underline">
                    {aboutData?.phone_number}
                  </a>

                  <div className="separator"></div>

                  <p className="contact-label">Location</p>

                  <address className="body-4"><a className="contact-number" href="https://maps.app.goo.gl/3z4dyF7uz5y7bNiq6">{aboutData?.address}</a></address>

                  <p className="">Restaurant Timings</p>
                  <Table dataSource={aboutData?.opening_hours} columns={COULUMN_HEADINGS} pagination={false} className="transparent-table" />
                </div>
              </div>
              {/* </div> */}
            </section>

            <ul className="footer-list">
              <li>
                <a href="#" className="label-2 footer-link hover-underline">
                  Home
                </a>
              </li>
              <li>
                <a href="#menu" className="label-2 footer-link hover-underline">
                  Menus
                </a>
              </li>
              <li>
                <a href="#reservation" className="label-2 footer-link hover-underline">
                  Booking
                </a>
              </li>
            </ul>

            <ul className="footer-list">
              <li>
                <a href="https://youtube.com/@mughalirestaurant?si=M1TQAqsUzhNW4-ZH" className="label-2 footer-link hover-underline">
                  Tiktok
                </a>
              </li>
              <li>
                <a href="https://youtube.com/@mughalirestaurant?si=M1TQAqsUzhNW4-ZH" className="label-2 footer-link hover-underline">
                  Youtube
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=61557312908124" className="label-2 footer-link hover-underline">
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://api.whatsapp.com/send/?phone=353831304326&text&type=phone_number&app_absent=0" className="label-2 footer-link hover-underline">
                  Whatsapp
                </a>
              </li>
              <li>
                <a href="https://www.google.com/maps/place/Mughali+Restaurant/@53.4169678,-7.904833,19z/data=!4m6!3m5!1s0x485c497e034d8ed7:0x5eca403e6f1e5637!8m2!3d53.4169547!4d-7.9048605!16s%2Fg%2F11vr7x9h7m?entry=ttu" className="label-2 footer-link hover-underline">
                  Google Map
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-bottom">

            <p className="copyright">
              &copy; 2024{" "}
              <a
                href=""
                target="_blank"
                className="link"
              >
                Mughali Restaurant
              </a>.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
