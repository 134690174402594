import React, { useState, useContext } from "react";
import { message } from "antd";
import { bookTable } from "../api/other";
import LoginModal from "./modals/loginModal";
import SignupModal from "./modals/signupModal";
import { AuthenticationContext } from "./layout/layoutWrapper";
import ForgotPasswordModal from "./modals/forgotPassword";

export const Reservation = ({ aboutData }) => {
  const [startTime, setStartTime] = useState("text");
  const [endTime, setEndTime] = useState("text");
  const value = useContext(AuthenticationContext);

  const renderModals = () => (
    <>
      {value.loginModal && (
        <LoginModal
          modal={value.loginModal}
          setLoginModal={value.setLoginModal}
          setSignupModal={value.setSignupModal}
          setForgotPasswordModal={value.setForgotPasswordModal}
        />
      )}
      {value.signupModal && (
        <SignupModal
          modal={value.signupModal}
          setSignupModal={value.setSignupModal}
          setLoginModal={value.setLoginModal}
        />
      )}
      {value.forgotPasswordModal && (
        <ForgotPasswordModal
          modal={value.forgotPasswordModal}
          setLoginModal={value.setLoginModal}
          setForgotPasswordModal={value.setForgotPasswordModal}
        />
      )}
    </>
  );

  const [messageApi, contextHolder] = message.useMessage();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    no_of_person: null,
    date: "",
    start_time: "",
    end_time: "",
    note: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\+?\d{10,15}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePhoneNumber(formData.phone)) {
      messageApi.open({
        type: "error",
        content: "Invalid phone number!",
      });
      return;
    }

    try {
      if (
        formData?.name &&
        formData?.phone &&
        formData?.date &&
        formData?.no_of_person &&
        formData?.start_time &&
        formData?.end_time
      ) {
        const response = await bookTable(formData);
        if (response.status === 201) {
          messageApi.open({
            type: "success",
            content: "Reservation successful!",
          });
        } else {
          messageApi.open({
            type: "error",
            content: "Failed to make reservation. Please try again.",
          });
        }
      } else {
        messageApi.open({
          type: "error",
          content: "Fill all information!",
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        value.setSignupModal(true);
      } else {
        messageApi.open({
          type: "error",
          content: "Error in making reservation!",
        });
      }
    }

    setFormData({
      name: "",
      phone: "",
      no_of_person: 1,
      date: "",
      start_time: "13:00",
      end_time: "14:00",
      note: "",
    });
  };

  return (
    <section
      className="section reservation text-center has-bg-image"
      id="reservation"
    >
      {renderModals()}
      {contextHolder}
      <div className="container">
        <div className="form reservation-form bg-black-10">
          <form onSubmit={handleSubmit} className="form-left">
            <h2 className="headline-1 text-center">Online Reservation</h2>

            <p className="form-text text-center">
              Booking request{" "}
              <a href="tel:+353899705390" className="link">
                (089) 970-5390
              </a>{" "}
              or fill out the request form
            </p>

            <div className="input-wrapper">
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                autoComplete="off"
                className="input-field"
                value={formData.name}
                onChange={handleChange}
              />

              <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                autoComplete="off"
                className="input-field"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>

            <div className="input-wrapper">
              <div className="icon-wrapper">

                <img src="/static/frontend/images/people.png" width={40} height={30}
                  style={{
                    position: 'absolute',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '20px',
                    height: '20px'
                  }} />
                {/* <ion-icon name="person-outline" aria-hidden="true" style={{
                  color: 'white',
                  fontSize: '16px'
                }}></ion-icon> */}

                <input
                  type="number"
                  name="no_of_person"
                  min="1"
                  className="input-field"
                  value={formData.no_of_person}
                  onChange={handleChange}
                  placeholder="Enter number of people"
                />
              </div>
              <div className="icon-wrapper">
                {/* <ion-icon
                  name="calendar-clear-outline"
                  aria-hidden="true"
                  style={{
                    color: 'white',
                    fontSize: '16px'
                  }}
                ></ion-icon> */}

                <img src="/static/frontend/images/calender.png" width={40} height={30}
                  style={{
                    position: 'absolute',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '20px',
                    height: '20px'
                  }} />

                <input
                  id="datepicker"
                  type="date"
                  name="date"
                  className="input-field"
                  value={formData.date}
                  onChange={handleChange}
                />

                <ion-icon name="chevron-down" aria-hidden="true"
                  style={{
                    color: 'white',
                    fontSize: '16px'
                  }}></ion-icon>
              </div>
            </div>

            <div className="input-wrapper">
              <div className="icon-wrapper">
                <img src="/static/frontend/images/clock-icon.png" width={40} height={30}
                  style={{
                    position: 'absolute',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '20px',
                    height: '20px'
                  }} />
                {/* <ion-icon name="time-outline" aria-hidden="true"
                  style={{
                    color: 'white',
                    fontSize: '16px'
                  }}
                ></ion-icon> */}

                <input
                  id="start-timepicker"
                  type="time"
                  name="start_time"
                  className="input-field"
                  value={formData.start_time}
                  onChange={handleChange}
                />
                {/* <ion-icon name="chevron-down" aria-hidden="true"></ion-icon> */}
              </div>

              <div className="icon-wrapper">

                <img src="/static/frontend/images/clock-icon.png" width={40} height={30}
                  style={{
                    position: 'absolute',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '20px',
                    height: '20px'
                  }} />
                {/* <ion-icon name="time-outline" aria-hidden="true"
                  style={{
                    color: 'white',
                    fontSize: '16px'
                  }}
                ></ion-icon> */}
                <input
                  id="end-timepicker"
                  type="time"
                  name="end_time"
                  className="input-field"
                  value={formData.end_time}
                  onChange={handleChange}
                />

                {/* <ion-icon name="chevron-down" aria-hidden="true"></ion-icon> */}
              </div>
            </div>
            {/* </div> */}

            <textarea
              name="note"
              placeholder="Notes"
              autoComplete="off"
              className="input-field"
              value={formData.note}
              onChange={handleChange}
            ></textarea>

            <button type="submit" className="btn btn-secondary">
              <span className="text text-1">Book A Table</span>
              <span className="text text-2" aria-hidden="true">
                Book A Table
              </span>
            </button>
          </form>

          <div
            className="form-right text-center"
            style={{
              backgroundImage: `url('/static/frontend/images//form-pattern.png')`,
            }}
            id="timings"
          >
            <h2 className="headline-1 text-center">Contact Us</h2>

            <p className="contact-label">Booking Request</p>

            <a
              href="tel:+353899705390"
              className="body-1 contact-number hover-underline"
            >
              {aboutData?.phone_number}
            </a>

            <div className="separator"></div>

            <p className="contact-label">Location</p>

            <address className="body-4"><a className="contact-number hover-underline" href="https://maps.app.goo.gl/3z4dyF7uz5y7bNiq6">{aboutData?.address}</a></address>
          </div>
        </div>
      </div>
    </section>
  );
};
