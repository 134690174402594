import React, { useState, useEffect } from "react";
import { Badge } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getDiscountDetails } from "../api/other";

export const Topbar = ({ totalItems, aboutData }) => {
  const navigate = useNavigate();
  const [discountDetails, setDiscountDetails] = useState(null);
  const handleCart = () => {
    navigate("/cart");
  };

  const getTopBarDetails = async () => {
    try {
      const res = await getDiscountDetails();
      setDiscountDetails(JSON.parse(res?.discount_on_order));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTopBarDetails();
  }, []);

  return (
    <>
      {discountDetails && (
        <div className="w-full bg-red-800 text-white fixed top-0 left-0 right-0 py-2 z-50">
          <div className="overflow-hidden whitespace-nowrap flex items-center">
            <div className="flex animate-marquee p-2">
              <div className="flex">
                <span className="px-4">
                  Get {discountDetails?.discount}% discount On order above €{discountDetails?.amount}
                </span>
                <span className="px-4">
                  Get {discountDetails?.discount}% discount On order above €{discountDetails?.amount}
                </span>
                <span className="px-4">
                  Get {discountDetails?.discount}% discount On order above €{discountDetails?.amount}
                </span>
                <span className="px-4">
                  Get {discountDetails?.discount}% discount On order above €{discountDetails?.amount}
                </span>
                <span className="px-4">
                  Get {discountDetails?.discount}% discount On order above €{discountDetails?.amount}
                </span>
                <span className="px-4">
                  Get {discountDetails?.discount}% discount On order above €{discountDetails?.amount}
                </span>
                <span className="px-4">
                  Get {discountDetails?.discount}% discount On order above €{discountDetails?.amount}
                </span>
                <span className="px-4">
                  Get {discountDetails?.discount}% discount On order above €{discountDetails?.amount}
                </span>
                <span className="px-4">
                  Get {discountDetails?.discount}% discount On order above €{discountDetails?.amount}
                </span>
                <span className="px-4">
                  Get {discountDetails?.discount}% discount On order above €{discountDetails?.amount}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="pt-16">
        <div className="topbar">
          <div className="container py-4 sm:max-w-full">
            <address className="topbar-item">
              <div className="icon">
                <ion-icon name="location-outline" aria-hidden="true" />
              </div>

              <span className="span">
                <a href={aboutData?.address_url} className="topbar-item link">
                  {aboutData?.address}
                </a>
              </span>
            </address>

            <div className="separator"></div>

            <a href="#aboutus" className="topbar-item item-2">
              <div className="icon">
                <ion-icon name="time-outline" aria-hidden="true" />
              </div>

              <span className="span">Timings </span>
            </a>

            <a href="tel:+353899705390" className="topbar-item link">
              <div className="icon">
                <ion-icon name="call-outline" aria-hidden="true" />
              </div>

              <span className="span">{aboutData?.phone_number}</span>
            </a>

            <div className="separator"></div>

            <a href="mailto:mughalirestaurant@gmail.com" className="topbar-item link">
              <div className="icon">
                <ion-icon name="mail-outline" aria-hidden="true" />
              </div>

              <span className="span">mughalirestaurant@gmail.com</span>
            </a>
            <div className="md:pr-6 opacity-100">
              <Badge count={totalItems} onClick={handleCart}>
                <ShoppingCartOutlined className="z-[300]" />
              </Badge>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
